@import url("https://fonts.googleapis.com/css?family=Roboto");
* {
  box-sizing: border-box;
}
@media only screen and (max-width: 45rem) {
  .card {
    background-color: #00f;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 60% 40%;
    height: 10rem;
    width: 10rem;
    max-width: 75rem;
    margin: 10rem auto;
    box-shadow: 10px 10px 93px 0px rgba(0,0,0,0.75);
    font-family: "Robo", sans-serif;
  }
}
@media only screen and (min-width: 48rem) {
  .card {
    background-color: #f00;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 20% 20% 30% 30%;
    height: 60rem;
    width: 30rem;
    max-width: 30rem;
    margin: 10rem auto;
    box-shadow: 10px 10px 93px 0px rgba(0,0,0,0.75);
    font-family: "Robo", sans-serif;
  }
  .thumbnail {
    grid-row: 1;
    grid-column: 1;
    min-width: 100%;
    min-height: 100%;
    box-shadow: 10px 10px 60px 0px rgba(0,0,0,0.75);
    overflow: hidden;
    position: relative;
    justify-self: stretch;
  }
  .thumbnail img {
    left: 30px;
    top: -30px;
    width: 100%;
    height: 100%;
    min-width: inherit;
  }
  .about {
    grid-row: 2/span 4;
    grid-column: 2;
    margin-left: 30px;
    margin-right: 30px;
  }
  .about h1 {
    padding-top: 0px;
    font-size: 1.8rem;
    color: #4b4b4b;
  }
  .about h2 {
    margin-top: -15px;
    font-size: 1.15rem;
    color: #4b4b4b;
  }
  .about .separator {
    margin-top: 10px;
    border: 1px solid #c3c3c3;
  }
  .about p {
    text-align: justify;
    padding-top: 0px;
    font-size: 1rem;
    line-height: 150%;
    color: #4b4b4b;
    overflow: hidden;
  }
}
@media only screen and (min-width: 64rem) {
  .card {
    display: grid;
    grid-template-columns: 15% 15% 35% 35%;
    grid-template-rows: 60% 40%;
    height: 30rem;
    width: 60rem;
    max-width: 75rem;
    margin: 10rem auto;
    background-color: #fff;
    box-shadow: 10px 10px 93px 0px rgba(0,0,0,0.75);
    font-family: "Robo", sans-serif;
  }
  .thumbnail {
    grid-row: 1;
    grid-column: 1/span 2;
    min-width: 100%;
    min-height: 100%;
    box-shadow: 10px 10px 60px 0px rgba(0,0,0,0.75);
    overflow: hidden;
    position: relative;
    justify-self: stretch;
  }
  .thumbnail img {
    left: 30px;
    top: -30px;
    width: 100%;
    height: 100%;
    min-width: inherit;
  }
  .about {
    grid-row: 1/span 2;
    grid-column: 3/span 4;
    margin-left: 30px;
    margin-right: 30px;
  }
  .about h1 {
    padding-top: 0px;
    font-size: 1.8rem;
    color: #4b4b4b;
  }
  .about h2 {
    margin-top: -15px;
    font-size: 1.15rem;
    color: #4b4b4b;
  }
  .about .separator {
    margin-top: 10px;
    border: 1px solid #c3c3c3;
  }
  .about p {
    text-align: justify;
    padding-top: 0px;
    font-size: 1rem;
    line-height: 150%;
    color: #4b4b4b;
  }
  .date {
    grid-row: 2;
    grid-column: 1;
  }
  h5 {
    margin-top: 3.5rem;
    margin-left: 0.8rem;
    font-size: 6rem;
    color: #c3c3c3;
    position: absolute;
  }
  h6 {
    margin-top: 9rem;
    margin-left: 0.8rem;
    font-size: 2rem;
    color: #c3c3c3;
    position: absolute;
  }
  .social {
    grid-row: 2;
    grid-column: 2;
    position: relative;
  }
  ul {
    position: absolute;
    margin-top: 9rem;
    right: 0;
  }
  li {
    display: inline;
    list-style: none;
    padding-right: 0.3rem;
    font-size: 2rem;
    color: #7b7b7b;
  }
  .resume {
    grid-row: 2;
    grid-column: 4;
    position: relative;
  }
  .resume i {
    margin-top: 10rem;
    margin-left: 5rem;
    position: absolute;
    padding-top: 14px;
    background-color: #1875d0;
    width: 60px;
    height: 60px;
    font-size: 2.1rem;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    box-shadow: 5px 5px 30px 0px rgba(0,0,0,0.75);
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.App {
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}
